/*
 * @Author: Joe Steccato
 * @Date:   2023-04-13 13:10:43
 * @Last Modified by:   Joe Steccato
 * @Last Modified time: 2023-11-27 15:56:19
 */

import { useState, useEffect } from "react";
import axios from "../axios";

export const useOpenRequests = (onlyVisible = false) => {
    const [loading, setLoading] = useState(true);
    const [titleList, setTitleList] = useState([]);
    const [requestList, setRequestList] = useState([]);

    useEffect(() => {
        fetchOpenRequests();
    }, []);

    const fetchOpenRequests = async () => {
        setLoading(true);
        const result = await axios.get(
            onlyVisible
                ? "/api/music/open-requests/?password=music"
                : "/api/music/open-requests/?password=music&all=true"
        );
        const data = result.data;
        const requestTitles = ["General Submission"];
        data.forEach((request) => {
            requestTitles.push(`#${request.request_number} ${request.title}`);
        });
        setTitleList(requestTitles);
        setRequestList(data);
        setLoading(false);
    };

    return {
        requestsLoading: loading,
        requestList,
        titleList,
        refreshRequests: fetchOpenRequests,
    };
};

/*
 * @Author: Joe Steccato
 * @Date:   2023-04-27 17:43:18
 * @Last Modified by:   Joe Steccato
 * @Last Modified time: 2023-11-27 15:40:00
 */
import { useState } from "react";
import axios from "../../axios";

import ComposerInput from "./ComposerInput";
import TrackCollaborator from "./TrackCollaborator";

import styles from "./TrackForm.module.scss";

const TrackTitleComposer = ({ track, updateTrackFields, handleUserInput }) => {
    const [titleExists, setTitleExists] = useState(false);
    let titleTimeout = null;

    const handleTitleChange = (e) => {
        handleUserInput(e);

        if (titleTimeout) clearTimeout(titleTimeout);
        titleTimeout = setTimeout(function () {
            try {
                checkIfUniqueTitle(e.target.value);
            } catch (err) {}
        }, 300);
    };

    // Set Composer Payment Info based on defaults from VLID table
    const setPayment = (s) => {
        updateTrackFields({
            trackComposer: s.name,
            trackSubmissionType: s.defaultPaymentType || "",
            trackFee: s.defaultFee || "",
        });
    };

    // Check if for unique track title
    const checkIfUniqueTitle = async (title) => {
        if (title === "" || title === null || title === undefined) {
            setTitleExists(false);
            return;
        }

        const trimmedTitle = title.trim();
        const result = await axios.get(
            `/api/music/tracks/exists/?title=${trimmedTitle}`
        );
        setTitleExists(result.data.exists);
    };

    return (
        <div className={styles.section}>
            <input
                onBlur={(e) =>
                    updateTrackFields({ [e.target.name]: e.target.value })
                }
                placeholder="Title"
                name="trackTitle"
                className={styles.inputLarge}
                value={track["trackTitle"]}
                onChange={handleTitleChange}
            />

            {titleExists && (
                <div className={styles.titleError}>WARNING: Title exists.</div>
            )}

            <ComposerInput
                onBlur={() => {}}
                name="trackComposer"
                placeholder="Composer"
                isAdmin={true}
                thisComposer={null}
                className={styles.inputLarge}
                value={track["trackComposer"] || ""}
                composerChanged={(e) => {
                    handleUserInput({
                        target: { value: e, name: "trackComposer" },
                    });
                }}
                setPayment={(s) => {
                    updateTrackFields({
                        trackComposer: s.name,
                        trackSubmissionType: s.defaultPaymentType || "",
                        trackFee: s.defaultFee || "",
                    });
                }}
            />

            <TrackCollaborator
                {...{
                    track,
                    updateTrackFields,
                    handleUserInput,
                }}
            />
        </div>
    );
};

export default TrackTitleComposer;

/*
 * @Author: Joe Steccato
 * @Date:   2023-07-17 11:20:40
 * @Last Modified by:   Joe Steccato
 * @Last Modified time: 2023-07-17 12:09:21
 */

import styles from "./KeywordSubcategory.module.scss";

const KeywordSubcategory = (props) => {
    const { subcategory, selected, type } = props;

    if (!subcategory || subcategory.name === "Key") return null;

    return (
        <div className={styles.container}>
            <b>
                <u>{subcategory.name}</u>
            </b>

            {subcategory[type].map((kw) => {
                if (kw.name === "Specify BPM") return null;
                const isSelected = selected.includes(kw.vlid);

                return (
                    <div
                        key={kw.vlid}
                        className={`${styles.keywordItem} ${
                            isSelected ? styles.selectedItem : ""
                        }`}
                        onClick={() => props.toggleKeyword(kw.vlid, isSelected)}
                    >
                        <div className={styles.keywordText}>
                            {isSelected ? <>&#10003;</> : null} {kw.name}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default KeywordSubcategory;

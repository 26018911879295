import { useState, useEffect } from "react";
import axios from "../axios";

const useKeywordsInstrumentsTags = (type) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const result = await axios.get(
                    `/api/music/${type}/categories/`
                );
                setData(result.data);
            } catch (e) {
                console.error(e);
                setError(e);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return { data, loading, error };
};

export default useKeywordsInstrumentsTags;

import React, { useState, useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { useCopyClipboard } from "@lokibai/react-use-copy-clipboard";
import axios from "../../axios";
import "rc-tooltip/assets/bootstrap_white.css";
import { ReactSVG } from "react-svg";

import KeywordSubcategory from "./KeywordSubcategory";

import useKeywordsInstrumentsTags from "../../Hooks/useKeywordsInstrumentsTags";

import "react-tabs/style/react-tabs.css";
import "../../styles/InstructionalPopup.css";
import "../../styles/ExportFilesPopup.css";

import styles from "./TrackTagger.module.scss";

var mood_order = [
    "Abstract",
    "Atmospheric",
    "Energetic",
    "Strength",
    "Musical Feel",
    "Dramatic",
    "Emotional",
    "Sad",
    "Relaxed",
    "Romantic",
    "Comical/ Humorous",
    "Positive/ Optimistic",
];
var style_order = [
    "Film/ TV/ Stage",
    "Orchestral",
    "Settings",
    "Corporate",
    "Sports",
    "Children",
    "Trailer Music",
    "Drama",
    "Underscores",
    "Electronica/ Dance",
    "Pop",
    "Rock",
    "Blues",
    "Country",
    "Urban",
    "Reggae",
    "Religious",
    "Americana",
    "Traditional Dance",
    "Classical",
    "Vocals",
    "Latin",
    "International",
    "Holiday/ Special Occasion",
    "Jazz",
];

// Helper function to sort subcategories
function sortSubcats(categories, order) {
    // Create a mapping for quick lookup
    const categoryMap = Object.fromEntries(
        categories.map((cat) => [cat.name, cat])
    );

    // Sort based on the order array
    return order
        .map((subcategory) => categoryMap[subcategory] || null)
        .filter(Boolean);
}

// Main function to parse tags
function parseTags(categories) {
    // Find Mood and MusicalStyle categories
    const moodCategory = categories.find((cat) => cat.name === "Mood");
    const styleCategory = categories.find((cat) => cat.name === "MusicalStyle");

    // Sort subcategories
    const mood = moodCategory
        ? sortSubcats(moodCategory.subcategories, mood_order)
        : [];
    const style = styleCategory
        ? sortSubcats(styleCategory.subcategories, style_order)
        : [];

    return { mood, style };
}

const TrackTagger = (props) => {
    const initSelected = [];
    const composerSelected = [];

    // Separate composer custom keywords from Filemakerr VLID keywords
    props.currentKeywords.forEach((k) => {
        if (k.substring(0, 2) !== "VL") composerSelected.push(k);
        else initSelected.push(k);
    });

    const [selected, setSelected] = useState(initSelected);
    const [isCopied, setCopied] = useCopyClipboard("");
    const [showCopied, setShowCopied] = useState(false);

    const { data: keywords } = useKeywordsInstrumentsTags("keywords");
    const { data: instruments } = useKeywordsInstrumentsTags("instruments");
    const { data: tags } = useKeywordsInstrumentsTags("tags");
    const formattedTags = parseTags(tags);

    useEffect(() => {
        if (isCopied) {
            setShowCopied(true);
            setTimeout(() => {
                setShowCopied(false);
            }, 4000);
        }
    }, [isCopied]);

    const toggleKeyword = (id, isSelected) => {
        if (isSelected) {
            const new_sel = [...selected];
            var index = new_sel.indexOf(id);
            if (index !== -1) {
                new_sel.splice(index, 1);
            }
            setSelected(new_sel);
            props.setSelected(new_sel);
        } else {
            const new_sel = [...selected, id];
            setSelected(new_sel);
            props.setSelected(new_sel);
        }
    };

    return (
        <div>
            {props.instrumentsOnly ? (
                <div className={styles.container}>
                    <div className={styles.columnContainer}>
                        {instruments.map((k, i) => {
                            return (
                                <KeywordSubcategory
                                    key={i}
                                    keyword={k}
                                    selected={selected}
                                    toggleKeyword={toggleKeyword}
                                />
                            );
                        })}
                    </div>
                    <button
                        onClick={() =>
                            props.closePopup([...selected, ...composerSelected])
                        }
                    >
                        Done
                    </button>
                </div>
            ) : (
                <div>
                    <div className={styles.trackTitle}>
                        {props.trackTitle && (
                            <div>
                                <b>{props.trackTitle}</b>
                                <br />
                            </div>
                        )}

                        <div className={styles.selectedCount}>
                            {selected.length} tags selected.
                        </div>
                    </div>
                    <Tabs default={0}>
                        <TabList>
                            <Tab>Keywords</Tab>
                            <Tab>Instruments</Tab>
                            <Tab>Tags - Mood</Tab>
                            <Tab>Tags - Musical Style</Tab>
                        </TabList>
                        <TabPanel>
                            <div className={styles.tabContainer}>
                                {keywords.map((category, i) => {
                                    return category.subcategories.map(
                                        (subcategory, j) => {
                                            return (
                                                <KeywordSubcategory
                                                    key={i + "_" + j}
                                                    type={"keywords"}
                                                    subcategory={subcategory}
                                                    selected={selected}
                                                    toggleKeyword={
                                                        toggleKeyword
                                                    }
                                                />
                                            );
                                        }
                                    );
                                })}
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className={styles.tabContainer}>
                                {instruments.map((category, i) => {
                                    return category.subcategories.map(
                                        (subcategory, j) => {
                                            return (
                                                <KeywordSubcategory
                                                    key={i + "_" + j}
                                                    type={"instruments"}
                                                    subcategory={subcategory}
                                                    selected={selected}
                                                    toggleKeyword={
                                                        toggleKeyword
                                                    }
                                                />
                                            );
                                        }
                                    );
                                })}
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className={styles.tabContainer}>
                                {formattedTags.mood.map((subcategory, i) => {
                                    return (
                                        <KeywordSubcategory
                                            key={i}
                                            type={"tags"}
                                            subcategory={subcategory}
                                            selected={selected}
                                            toggleKeyword={toggleKeyword}
                                        />
                                    );
                                })}
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div className={styles.tabContainer}>
                                {formattedTags.style.map((subcategory, i) => {
                                    return (
                                        <KeywordSubcategory
                                            key={i}
                                            type={"tags"}
                                            subcategory={subcategory}
                                            selected={selected}
                                            toggleKeyword={toggleKeyword}
                                        />
                                    );
                                })}
                            </div>
                        </TabPanel>
                    </Tabs>
                    {!props.isPopup && selected.length > 0 && (
                        <div
                            className={styles.taggerCopyButton}
                            onClick={() => setCopied(selected || [])}
                        >
                            <ReactSVG
                                src={process.env.PUBLIC_URL + "./svgs/copy.svg"}
                                renumerateIRIElements={false}
                                wrapper="span"
                                className={"tagging_svg " + styles.tagging_svg}
                                style={{ width: "16px", marginRight: "6px" }}
                                id=""
                            />
                            <div>
                                {showCopied
                                    ? "Copied!"
                                    : `Copy ${selected.length} Tags`}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default TrackTagger;

/*
* @Author: Joe Steccato
* @Date:   2023-11-27 15:28:32
* @Last Modified by:   Joe Steccato
* @Last Modified time: 2023-11-28 12:53:57
*/
import axios from 'axios';

const instance = axios.create({
  // baseURL: 'http://localhost:8080',
  baseURL: 'https://api.4elementsmusic.com',

  withCredentials: true, // Include cookies if required
});

export default instance;